<!-- =========================================================================================
	File Name: Login.vue
	Description: Login Page
	----------------------------------------------------------------------------------------
	Item Name: Vuesax Admin - VueJS Dashboard Admin Template
	Version: 1.1
	Author: Pixinvent
	Author URL: hhttp://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div
              class="
                vx-col
                hidden
                sm:hidden
                md:hidden
                lg:block
                lg:w-1/2
                mx-auto
                self-center
              "
            >
              <img
                src="@/assets/images/logo/Cyberbank konecta logo.svg"
                alt="login"
                class="mx-auto"
                width="60%"
              />
            </div>

            <!-- domain / password -->
            <form
              v-show="enterpass"
              @submit.prevent="login"
              class="
                vx-col
                sm:w-full
                md:w-full
                lg:w-1/2
                mx-auto
                self-center
                bg-white
              "
            >
              <div class="p-8">
                <div class="vx-card__title mb-8 word-break">
                  <h4
                    class="mb-4"
                    v-text="lang.login.title[languageSelected]"
                  ></h4>
                  <p>
                    <span
                      v-text="lang.login.goBackText[languageSelected]"
                    ></span>
                    <strong>{{ userName }}.</strong>
                    <a class="not-you" @click="goBack()">
                      {{ lang.login.goBackQuestion[languageSelected] }}
                    </a>
                  </p>
                </div>

                <label for="domain-select" v-if="domains.length">
                  {{ domainLabel }}
                </label>
                <multiselect
                  id="domain-select"
                  v-if="domains.length"
                  v-model="selectedDomain"
                  :options="domains"
                  track-by="index"
                  label="text"
                  :searchable="true"
                  :show-labels="false"
                  placeholder
                />

                <vs-input
                  ref="password"
                  id="inputPasswordLogin"
                  v-if="enterpass"
                  v-model="password"
                  type="password"
                  icon="icon icon-lock"
                  icon-pack="feather"
                  :label-placeholder="
                    lang.login.form.password[languageSelected]
                  "
                  class="w-full mb-4 no-icon-border"
                  data-cy="txtPassword"
                  required
                />
                <div class="flex flex-wrap justify-between my-5">
                  <router-link
                    to="/pages/reset-password"
                    v-text="lang.login.forgotPassword[languageSelected]"
                  ></router-link>
                </div>
                <vs-button
                  id="buttonPassworLogin"
                  v-text="lang.login.form.loginButton[languageSelected]"
                  data-cy="btnSubmitP"
                  :disabled="sending"
                ></vs-button>
              </div>
            </form>

            <!-- username -->
            <form
              v-show="!enterpass"
              @submit.prevent="getDomains"
              class="
                vx-col
                sm:w-full
                md:w-full
                lg:w-1/2
                mx-auto
                self-center
                bg-white
              "
            >
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4
                    class="mb-4"
                    v-text="lang.login.title[languageSelected]"
                  ></h4>
                  <p v-text="lang.login.description[languageSelected]"></p>
                </div>
                <vs-input
                  ref="username"
                  v-model="userName"
                  icon="icon icon-mail"
                  icon-pack="feather"
                  :label-placeholder="lang.login.form.email[languageSelected]"
                  class="w-full mb-6 no-icon-border"
                  data-cy="txtEmail"
                  required
                />
                <vs-button
                  v-text="lang.login.form.continueButton[languageSelected]"
                  data-cy="btnSubmitE"
                  :disabled="sending"
                ></vs-button>
              </div>
            </form>
          </div>
        </div>
      </vx-card>
      <small style="bottom: 5px; right: 5px; position: fixed">
        <strong>v{{ appVersion }}</strong>
        <!-- ({{ version }}) -->
      </small>
    </div>
  </div>
</template>

<script>
import { sha256 } from 'js-sha256'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import konecta from '@/services/konecta'
// import version from '../../version'
import packageJson from '../../../package.json'
import { themeConfig, themeColors } from '../../../themeConfig'

const ROUTES_BY_ROL = {
  company_superadmin: '/bot-maker',
  company_editor: '/bot-maker',
  company_agent: '/attention-requests',
  company_supervisor: '/metrics',
  company_roles_manager: '/users',
  company_sys_admin_default: '/companies',
  company_sys_admin: '/companies/add',
  default: '/'
}

export default {
  data() {
    return {
      // datos de login
      userName: '',
      password: '',

      // respuesta de login
      response: {},
      sending: false,
      message: '',
      enterpass: false,
      domains: [],
      selectedDomain: ''
    }
  },
  components: {
    Multiselect: () => import('vue-multiselect')
  },
  methods: {
    ...mapActions([
      'updateAndSaveSession',
      'getCompanySettings',
      'setCompanyLogo',
      'updateTheme',
      'updateLoginMethod'
    ]),
    ...mapActions('companies', ['getCompanies']),
    ...mapMutations([
      'UPDATE_THEME_COLORS',
      'UPDATE_LANGUAGE',
      'UPDATE_SIDEBAR_ITEMS_MIN',
      'SET_CHANGE_PASSWORD'
    ]),
    async getDomains() {
      let data = {
        email: this.userName
      }
      const response = await konecta.post('/user/getDomain', data)
      if (response.data.length > 0) {
        this.domains = response.data.map((element, index) => {
          return {
            text: element.name,
            index: index
          }
        })
      }
      this.enterpass = true
      this.$nextTick(() => this.setFocusOnInput('password'))
    },
    login() {
      let data = {
        email: this.userName,
        password:
          this.selectedDomain == '' ? sha256(this.password) : this.password,
        domname: this.selectedDomain.text
      }
      this.sending = true
      konecta
        .post('/user/login', data)
        .then(async response => {
          this.response = response
          this.sending = false

          if (this.response.status == 200) {
            const companyTheme = this.response.data.theme

            if (companyTheme) {
              this.updateCompanyTheme(companyTheme)
            }
            const companyLanguage = this.response.data.lang
            if (companyLanguage) {
              // moment, store
              this.$moment.locale(companyLanguage)
              this.UPDATE_LANGUAGE(companyLanguage)
            }

            const authMethod = this.response.data.authMethod
            if (authMethod) {
              this.updateCompanyLogin(authMethod)
            }

            this.updateAndSaveSession(this.response.data)

            /** Login Load Company Settings */
            await this.getCompanySettings()

            this.redirectUserByRol(this.response.data)
            this.UPDATE_SIDEBAR_ITEMS_MIN(false)
          } else if (this.response.status == 400) {
            this.responseToast(
              this.lang.login.form.response.error400.title[
                this.languageSelected
              ],
              this.lang.login.form.response.error400.description[
                this.languageSelected
              ],
              'warning'
            )
          }
        })
        .catch(error => {
          /** Verify the error has a response */
          if (error.response && error.response.status == 400) {
            this.responseToast(
              this.lang.login.form.response.error400.title[
                this.languageSelected
              ],
              this.lang.login.form.response.error400.description[
                this.languageSelected
              ],
              'warning'
            )
          } else if (
            error.response &&
            error.response.status == 401 &&
            error.response.data == 'Banned'
          ) {
            this.$router.push('/pages/banned')
          }
          this.sending = false
        })
    },
    async initCompanies() {
      this.$vs.loading()
      try {
        await this.getCompanies()
        this.$vs.loading.close()
      } catch (error) {
        console.error(error)
        this.$vs.notify({
          title: 'Oops!',
          text: this.lang.companies.messages.list.error[this.languageSelected],
          color: 'danger',
          position: 'bottom-right'
        })
        this.$vs.loading.close()
      }
    },
    async redirectUserByRol(userData) {
      const redirectRoute = userData.redirect
      const rol = userData.roles && userData.roles[0]

      if (redirectRoute) {
        if (rol === 'company_sys_admin') {
          this.SET_CHANGE_PASSWORD(true)
        }
        return this.$router.push(redirectRoute)
      }
      let userSection =
        rol && ROUTES_BY_ROL[rol]
          ? ROUTES_BY_ROL[rol]
          : ROUTES_BY_ROL['default']

      if (rol === 'company_sys_admin') {
        await this.initCompanies()
        // sys_admin has companies
        if (this.companies || this.companies.length > 0) {
          userSection = ROUTES_BY_ROL[`${rol}_default`]
        }
      }
      this.$router.push(userSection)
    },
    toggleClassInBody(className) {
      if (className == 'dark') {
        if (document.body.className.match('theme-semi-dark'))
          document.body.classList.remove('theme-semi-dark')
        document.body.classList.add('theme-dark')
      } else if (className == 'semi-dark') {
        if (document.body.className.match('theme-dark'))
          document.body.classList.remove('theme-dark')
        document.body.classList.add('theme-semi-dark')
      } else {
        if (document.body.className.match('theme-dark'))
          document.body.classList.remove('theme-dark')
        if (document.body.className.match('theme-semi-dark'))
          document.body.classList.remove('theme-semi-dark')
      }
    },

    updateCompanyTheme(companyTheme) {
      if (companyTheme.companyLogo) {
        this.setCompanyLogo(companyTheme.companyLogo)
      }
      if (companyTheme.name) {
        this.updateTheme(companyTheme.name)
        this.toggleClassInBody(companyTheme.name)
      }
      if (companyTheme.colors) {
        this.UPDATE_THEME_COLORS(companyTheme.colors)
        this.$vs.theme(companyTheme.colors)
      }
    },
    updateCompanyLogin(method) {
      this.updateLoginMethod(method)
    },
    responseToast(title, text, color) {
      this.$vs.notify({
        title,
        text,
        color
      })
    },
    goBack() {
      this.userName = ''
      this.enterpass = false
      this.password = ''
      this.domains = []
      this.selectedDomain = ''
      this.$nextTick(() => this.setFocusOnInput('username'))
    },
    setDefaultTheme() {
      this.updateTheme(themeConfig.theme)
      this.$vs.theme(themeColors)
    },
    setFocusOnInput(inputName) {
      let inputEl = this.$refs[inputName].$el.querySelector('input')
      inputEl.focus()
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session', 'packageVersion']),
    ...mapGetters('companies', ['companies']),
    // version() {
    //   return version.version
    // },
    appVersion() {
      return packageJson.version
    },
    domainLabel() {
      return this.lang.login.form.selectDomainLabel[this.languageSelected]
    }
  },
  mounted() {
    this.setDefaultTheme()
    localStorage.removeItem('session')
    this.$nextTick(() => this.setFocusOnInput('username'))
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.not-you {
  cursor: pointer;
  margin-left: 3px;
}
.word-break {
  word-break: break-word;
}
</style>
